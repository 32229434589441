<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="editCourseAddress"
        ref="editCourseAddress"
        class="custom-form pt-2"
        @submit.prevent="updateAddress"
      >
        <v-row>
          <v-col
            cols="12"
            class="pt-8"
          >
            <v-text-field
              outlined
              label="Numer telefonu"
              v-model="address.phoneNumber"
              :rules="[rules.required, rules.phoneNumber]"
              v-mask="getFieldMask('phoneNumber')"
              placeholder="Wpisz numer telefonu"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="address.notes"
              outlined
              :rows="3"
              auto-grow
              label="Notatki"
              hide-details
              placeholder="Wpisz notatki"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="editCourseAddress"
      >
        Edytuj
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import rules from '../../utils/validators'
import { getFieldMask } from '../../utils'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      address: {
        phoneNumber: null,
        notes: null,
      },
      addressId: null,
      rules
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.course.isProcessing,
      item: state => state.layout.dialog.item,
      department: state => state.core.department
    }),
  },
  mounted () {
    const { phoneNumber, notes, id } = this.item.order?.address || {}
    this.address = {
      phoneNumber, notes
    }
    this.addressId = id
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      editAddress: 'addresses/editItem',
      getCourses: 'courses/getItems',
    }),
    getFieldMask,
    updateAddress () {
      const params = {
        tableName: 'addresses',
        params: this.address,
        id: this.addressId
      }
      this.editAddress(params)
        .then(() => {
          this.getCourses()
          this.afterFormSubmitted('Zaktualizowano lokalizację')
        })
    },
  }
}
</script>
